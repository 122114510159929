import * as screenfull from "screenfull";

import "@microsoft/dotnet-js-interop";

interface DotNetObject {
    invokeMethod<T>(methodIdentifier: string, ...args: any[]): T;

    invokeMethodAsync<T>(methodIdentifier: string, ...args: any[]): Promise<T>;
}

namespace GN2_8100.Client.Shared.Components {
    export class FullscreenToggleButton {
        DotNetInstance: DotNetObject;

        onchangeCallback = async () => {
            if (screenfull)
                await this.DotNetInstance.invokeMethodAsync("UpdateButtonState", screenfull.isFullscreen);
        };

        constructor(dotNetInstance: DotNetObject) {
            this.DotNetInstance = dotNetInstance;

            if (screenfull && screenfull.enabled) {
                screenfull.onchange(this.onchangeCallback);
            }
        }
    }
}

declare global {
    // explicitly add componentFunctions to the existing Window interface
    interface Window { componentFunctions: any; }
}

var component: GN2_8100.Client.Shared.Components.FullscreenToggleButton = null;

window.componentFunctions["FullscreenToggleButton_InteropInit"] =
    (instance: DotNetObject) => {
        if (component != null)
            return;

        component = new GN2_8100.Client.Shared.Components.FullscreenToggleButton(instance);

        return true;
    };

window.componentFunctions["FullscreenToggleButton_Dispose"] =
    () => {
        if (screenfull && component.onchangeCallback)
            screenfull.off('change', component.onchangeCallback);

        component = null;

        return true;
    }

window.componentFunctions["FullscreenToggleButton_Toggle"] =
    () => {
        if (screenfull && screenfull.enabled) {
            if (!screenfull.isFullscreen) {
                screenfull.request(document.documentElement);

                // gibt true zurück, um C#-seitig das Icon zu tauschen
                return true;
            }
            else {
                screenfull.exit();
            }
        }

        return false;
    }
